<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12">
        <iq-card>
          <template v-slot:headerTitle v-if="!propOpenedInModal">
            <h4 class="card-title">{{cvCardTitle}}</h4>
          </template>
          <template v-slot:body>
            <p v-show="!propOpenedInModal">{{cvCardSubHeader}}</p>
            <form action="#" v-if="vmSpcmCounsellorFormData && Object.keys(vmSpcmCounsellorFormData).length > 0">
              <div class="form-row">
                <div class="col-md-12 mb-3">
                  <label for="validationcounsellor_user_id">
                    {{cvCounsellorUserIdLabel}}</label>
                    <select v-model="vmSpcmCounsellorFormData.counsellor_user_id" class="form-control"  style="text-transform: capitalize;">
                      <option  v-for="(counselor_Id, index) of userCounsellorObjList" :key="(index+1)" :value="counselor_Id.user_id" >
                        {{counselor_Id.user_name}}
                      </option>
                    </select>
                </div>
                <div class="col-md-12 mb-3">
                  <label for="validationrole_desc">
                    {{cvRoleDesLabel}}</label>
                    <input v-model="vmSpcmCounsellorFormData.role_desc" type="text" class="form-control" required/>
                </div>
                <div class="col-md-12 mb-3">
                  <label for="validationjob_desc">
                    {{cvJobDescLabel}}</label>
                    <textarea v-model="vmSpcmCounsellorFormData.job_desc" type="text" class="form-control textarea" required></textarea>
                </div>
              </div>
              <div class="form-group">
                <button v-if="cvLoadingStatus" type="button" class="btn btn-primary" :disabled="cvLoadingStatus">
                   <b-spinner label="Spinning"></b-spinner>
                </button>
                <button type="button" class="btn btn-primary" @click="spcmCounsellorEdit()">{{cvSubmitBtn}}</button>
              </div>
            </form>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
      <div v-html="toastMsg"></div>
    </b-toast>
  </b-container>
</template>

<script>
import { SpcmCounsellors } from "../../../FackApi/api/SpcmCounsellor"
import ApiResponse from "../../../Utils/apiResponse"
import { socialvue } from "../../../config/pluginInit"
import userPermission from "../../../Utils/user_permission.js"
import { User } from "../../../FackApi/api/user.js"

export default {
  name: "SpcmCounsellorEdit",
  props: {
    propOpenedInModal: {
      type: Boolean,
      default: false
    },
    propSpcmCounsellorObj: {
      type: Object,
      default: function () {
        return {
          "counsellor_user_id": "",
          "role_desc": "",
          "job_desc": ""
        }
      }
    }
  },
  data () {
    return {
      cvCardTitle: "Counselor",
      cvCardSubHeader: "Counselor",
      cvSubmitBtn: "Edit",
      cvCounsellorUserIdLabel: "Select Members to Add to your team",
      cvRoleDesLabel: "Role",
      cvJobDescLabel: "Bio",
      showToast: false,
      cvLoadingStatus: false,
      toastMsg: null,
      toastVariant: "default",
      userCounsellorObjList: null,
      toastTitle: "Counselor List Updated",
      vmSpcmCounsellorFormData: {},
      spcmCounselorId: null
    }
  },
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    }
  },
  async beforeMount () {
    // Auth Access for Current User Role
    if (this.userData.user_role == "USERROLE11114") {
      // We will display only the sessions of the user, It is necessary when the user has multiple sessions and he/she wants to view the session
      // DONT CARE CASE
    }
    else if (!userPermission(this, this.userData, this.apiName)) {
      this.$router.back()
    }
  },
  async mounted () {
    socialvue.index()

    this.spcmCounselorId = this.propSpcmCounsellorObj && this.propSpcmCounsellorObj.spcm_counselor_id ? this.propSpcmCounsellorObj.spcm_counselor_id : this.$route.params.spcm_counselor_id
    await this.spcmCounsellorView()
    await this.userCounsellorList()
  },
  methods: {
    /**
     * validate
     */
    validate () {
      try {
        for (let index in this.vmSpcmCounsellorFormData) {
          if (!this.vmSpcmCounsellorFormData[index]) {
            this.toastMsg = "Missing " + index.split("_").join(" ")
            this.toastVariant = "danger"
            this.showToast = true
            return false
          }
        }
        return true
      }
      catch (err) {
        console.error("Exception occurred at validate() and Exception:", err.message)
        return null
      }
    },
    /**
     * spcmCounsellorView
     */
    async spcmCounsellorView () {
      try {
        let spcmCounsellorViewResp = await SpcmCounsellors.spcmCounsellorView(this, this.spcmCounselorId)
        if (spcmCounsellorViewResp && spcmCounsellorViewResp.resp_status) {
          this.vmSpcmCounsellorFormData = spcmCounsellorViewResp.resp_data.data
        }
      }
      catch (err) {
        console.error("Exception occurred at spcmCounsellorView() and Exception:", err.message)
      }
    },
    /**
     * spcmCounsellorEdit
     */
    async spcmCounsellorEdit () {
      try {
        // if (!this.validate()) {
        //   return false
        // }
        this.cvLoadingStatus = true
        let spcmCounsellorAddResp = await SpcmCounsellors.spcmCounsellorEdit(this, this.vmSpcmCounsellorFormData)
        await ApiResponse.responseMessageDisplay(this, spcmCounsellorAddResp)

        if (spcmCounsellorAddResp && !spcmCounsellorAddResp.resp_status) {
          return false
        }

        //  Emit if opened in Modal
        if (this.propOpenedInModal) {
          this.$emit("emitCloseSpcmCounsellorEditModal", this.vmSpcmCounsellorFormData)
        }
      }
      catch (err) {
        console.error("Exception occurred at spcmCounsellorEdit() and Exception:", err.message)
      }
      finally {
        this.cvLoadingStatus = false
      }
    },
    /**
    * userCounsellorList
    */
    async userCounsellorList () {
      try {
        let userCounsellorListResp = await User.userCounsellorList(this, this.whereFilter)
        if (userCounsellorListResp.resp_status) {
          this.userCounsellorObjList = userCounsellorListResp.resp_data.data
          this.totalRows = userCounsellorListResp.resp_data.count
        }
        else {
          this.userCounsellorObjList = []
          this.toastMsg = userCounsellorListResp.resp_msg
          this.toastVariant = "danger"
          this.showToast = true
        }
      }
      catch (err) {
        console.error("Exception occurred at userCounsellorList() and Exception:", err.message)
      }
    }
  }
}
</script>
<style scoped>
.textarea{
    height: 195px !important;
    overflow-y: scroll;
    line-height: 18px !important;
  }
</style>
