/*eslint-disable */
<template>
  <b-container fluid class="p-0">
    <b-row>
      <b-col lg="12">
        <iq-card class="stu_coun">
          <template v-slot:headerTitle>
            <b-row class="mt-2 px-3">
              <b-col class="col-12 col-sm-6 col-md-6 col-lg-6">
                <i class="font-size-20 mr-3 fa fa-users primary-color" aria-hidden="true"></i>
                <b><span v-if="propStudentCounsellorObj.user_name">{{ propStudentCounsellorObj.user_name }}'s&nbsp;</span>{{cvCardTitle}}</b>
              </b-col>
              <b-col class="col-12 col-sm-6 col-md-6 col-lg-6">
                <div v-if="propStudentCounsellorObj.user_id && (userData.user_id == propStudentCounsellorObj.counsellor_id || userData.user_role == 'USERROLE11111')">
                  <!-- Show for user specific team manage only -->
                  <button type="button" class="btn btn-primary pull-right mr-2 mb-1" @click="spcmOpenTeamManageEditModal('MODAL', propStudentCounsellorObj)" title="Manage Team">Manage Team</button>
                </div>
                <div v-if="!propStudentCounsellorObj.user_id && (userData.user_role == 'USERROLE11111' || userData.user_role == 'USERROLE11118')">
                  <!-- Show for overall team management not for user specific team manage -->
                  <button type="button" class="btn btn-primary pull-right mr-2 mb-1" @click="spcmCounsellorAdd('MODAL')" title="Add counselor">Add counselor</button>
                </div>
              </b-col>
            </b-row>
          </template>
          <template v-slot:body>
            <div class="row" id="stu_coun_team">
              <div v-for="(counselor,index) in stuConselorTeamObjList" :key="index" class="mt-2 col-12 col-sm-12 col-md-12 col-lg-12">
                <iq-card className="iq-card-block iq-card-stretch">
                  <template v-slot:body>
                      <div class="row counsolers_lists p-4">
                        <div class="counsolers_list_image" style="background-color: #f1f1f1;">
                          <img :src="counselor && counselor.image" alt="Club" v-if="counselor && counselor.image" style="width: 100%; margin: auto;margin: auto; display: block;">
                          <div class="first-letter" v-else v-html="getFirstLetterOfAString(counselor.user_name)"></div>
                        </div>
                        <div class="counsolers_list_data">
                          <div class="text-left" style="cursor: pointer;">
                            <p class="mb-2 title">
                            <span>Name: </span> {{counselor.user_name}}
                              <span class="float-right">
                                <b-button variant="mr-1 mb-1" size="sm" @click="spcmCounsellorEdit('MODAL', counselor)" v-if="(userData.user_role == 'USERROLE11111' || userData.user_role == 'USERROLE11118') && !propStudentCounselorTeam"><i class="ri-ball-pen-fill m-0 font-size-20 primary-color" title="Edit Counselor Details"></i></b-button>
                                <b-button variant="mr-1 mb-1" @click="showSpcmCounsellorDeleteDialog(counselor)" size="sm" v-if="(userData.user_role == 'USERROLE11111' || userData.user_role == 'USERROLE11118') && !propStudentCounselorTeam"><i class="ri-delete-bin-line m-0 font-size-20 primary-color" title="Remove the user from Counseling Team"></i></b-button>
                              </span>
                            </p>
                            <p class="mt-0 mb-0">
                              <span>Role:</span> {{counselor.role}}
                              <b-button variant="mr-1 mb-1" @click="spcmStuCounselorRoleChange(counselor)" size="sm" v-if="(userData.user_role == 'USERROLE11111' || userData.user_role == 'USERROLE11118') && propStudentCounselorTeam"><i class="ri-ball-pen-fill m-0 font-size-20 primary-color" title="Change Role"></i></b-button>
                            </p>
                            <p class="mt-0 mb-0">
                              <span>Bio: </span> {{counselor.job_desc}}
                            </p>
                          </div>
                        </div>
                      </div>
                  </template>
                </iq-card>
              </div>
              <div v-if="stuConselorTeamObjList == ''" class="ml-4">
                counselor not assign
              </div>
            </div>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-toast
      v-model="showToast"
      name="Toast"
      :auto-hide-delay="toastVariant === 'danger' ? 10000 : 1000"
      :variant="toastVariant"
      :title="toastTitle"
    >
      <div v-html="toastMsg"></div>
    </b-toast>

    <!-- Spcm Manage Team -->
    <b-modal
      v-model="showModelSpcmManageTeamEdit"
      scrollable
      :title="cvSpcmManageTeamModalHeader"
      size="xxl"
      hide-footer
    >
      <SpcmManageTeam :propOpenedInModal="true" :propCounsellorObj="spcmManageTeamObj" @emitCloseSpcmManageTeamModal="closeManageTeamtModal" />
    </b-modal><!-- Spcm Manage Team -->

    <b-modal
      v-model="showModelSpcmCounsellorEdit"
      scrollable
      :title="cvEditModalHeader"
      hide-footer
    >
      <SpcmCounsellorEdit :propOpenedInModal="true" :propSpcmCounsellorObj="spcmCounsellorEditObj" @emitCloseSpcmCounsellorEditModal="closeSpcmCounsellorEditModal" />
      <template #modal-footer="">
        <b-button size="sm" class="pull-left" @click="spcmCounsellorEdit('FULLPAGE')">
          {{cvbtnModalExpand}}
        </b-button>
        <b-button size="sm" @click="closeSpcmCounsellorEditModal()">
          {{cvbtnModalCancel}}
        </b-button>
      </template>
    </b-modal>

    <b-modal
      v-model="showModelSpcmCounsellorAdd"
      scrollable
      :title="cvAddModalHeader"
      hide-footer
    >
      <SpcmCounsellorAdd :propOpenedInModal="true" @emitCloseSpcmCounsellorAddModal="closeSpcmCounsellorAddModal" hide-footer />
      <template #modal-footer="">
        <b-button size="sm" class="pull-left" @click="spcmCounsellorAdd('FULLPAGE')">
          {{cvbtnModalExpand}}
        </b-button>
        <b-button size="sm" @click="closeSpcmCounsellorEditModal()">
          {{cvbtnModalCancel}}
        </b-button>
      </template>
    </b-modal>

    <b-modal v-model="showModelSpcmCounsellorDelete" size="sm" title="Delete" ok-title="Delete" cancel-title="Cancel">
      <p>{{cvDelMessage}}</p>
      <template #modal-footer="">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button size="sm" @click="showModelSpcmCounsellorDelete = false">
          {{cvbtnModalCancel}}
        </b-button>
        <b-button size="sm" variant="primary" @click="spcmCounsellorDelete()">
          {{cvbtnModalDelOk}}
        </b-button>
      </template>
    </b-modal>

    <b-modal
      v-model="showModalSpcmStuCounselorRoleEdit"
      scrollable
      :title="cvSpcmStuCounselorHeader"
      no-close-on-backdrop
      no-close-on-esc
      hide-footer
      class="xxl"
    >
      <form action="#">
        <div class="form-row">
          <div class="col-md-12 mb-3">
            <input v-model="vmspcmStudentTeamCounselorRoleChangeFormData.role" type="text" class="form-control" required />
          </div>
        </div>
        <div class="form-group">
          <button type="button" class="btn btn-primary" @click="spcmStudentTeamCounselorRoleEdit()">{{cvSubmitBtn}}</button>
        </div>
      </form>
      <!-- <template #modal-footer="">
        <b-button size="sm" @click="closespcmStuTeamCounselorRoleEditModal()">
          {{cvbtnModalCancel}}
        </b-button>
      </template> -->
    </b-modal>
  </b-container>
</template>

<script>
import { Spcms } from "../../../FackApi/api/Spcm.js"
import { socialvue } from "../../../config/pluginInit"
import SpcmManageTeam from "./SpcmManageTeam.vue"
import SpcmCounsellorEdit from "./SpcmCounsellorEdit.vue"
import SpcmCounsellorAdd from "./SpcmCounsellorAdd"
import { SpcmCounsellors } from "../../../FackApi/api/SpcmCounsellor.js"
import ApiResponse from "../../../Utils/apiResponse"

export default {
  name: "spcmCountryStrategyAdd",
  components: {
    SpcmManageTeam,
    SpcmCounsellorEdit,
    SpcmCounsellorAdd
  },
  props: {
    propOpenedInModal: {
      type: Boolean,
      default: false
    },
    propStudentCounsellorObj: {
      type: Object,
      default: function () {
        return {
          "counsellor_id": "", // Lead Counselor with double ll
          "counselor_team": ""
        }
      }
    },
    propStudentCounselorTeam: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      cvCardTitle: "Counseling Team",
      spcmManageTeamObj: null,
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      cvLoadingStatus: false,
      cvSpcmManageTeamModalHeader: "Manage Team",
      cvEditModalHeader: "Edit Counselor",
      cvAddModalHeader: "Add Counselor",
      cvbtnModalExpand: "Expand",
      cvbtnModalCancel: "Cancel",
      showModelSpcmManageTeamEdit: false,
      toastTitle: "Student Counseling Team ",
      cvDelMessage: "Are you sure?",
      cvbtnModalDelOk: "Delete",
      showModelSpcmCounsellorEdit: false,
      showModelSpcmCounsellorDelete: false,
      showModelSpcmCounsellorAdd: false,
      showModalSpcmStuCounselorRoleEdit: false,
      whereFilter: {},
      stuConselorTeamObjList: [],
      spcmCounsellorEditObj: null,
      cvSpcmStuCounselorHeader: "Student Counselor",
      vmspcmStudentTeamCounselorRoleChangeFormData: {},
      cvSubmitBtn: "save"
    }
  },
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    }
  },
  async mounted () {
    socialvue.index()
    await this.StudentCounselorTeamList()
  },
  methods: {
    /**
     * Student Counselor Team List
     */
    async StudentCounselorTeamList () {
      try {
        if (this.propStudentCounsellorObj) {
          if (!this.propStudentCounsellorObj.counselor_team || this.propStudentCounsellorObj.counselor_team.length <= 0) {
            this.propStudentCounsellorObj.counselor_team = this.propStudentCounsellorObj.counsellor_id // Lead Counselor with double ll
          }
          else if (!this.propStudentCounsellorObj.counselor_team.includes(this.propStudentCounsellorObj.counsellor_id)) {
            this.propStudentCounsellorObj.counselor_team += "," + this.propStudentCounsellorObj.counsellor_id // Lead Counselor with double ll
          }

          this.whereFilter.student_counselor_team = this.propStudentCounsellorObj.counselor_team
          this.whereFilter.spcm_id = this.propStudentCounsellorObj.spcm_id
        }
        else {
          this.whereFilter = null
        }

        let studentCounselorTeamResp = await Spcms.StudentCounselorTeamList(this, this.whereFilter)

        if (studentCounselorTeamResp.resp_status) {
          this.stuConselorTeamObjList = studentCounselorTeamResp.resp_data.data
        }
        else {
          this.toastMsg = studentCounselorTeamResp.resp_msg
          this.toastVariant = "danger"
          this.showToast = true
        }
      }
      catch (err) {
        console.error("Exception occurred at StudentCounselorTeamList() and Exception:", err.message)
      }
    },
    /**
     * getFirstLetterOfAString
     */
    getFirstLetterOfAString (string) {
      if (string) {
        return string.substr(0, 1)
      }
    },
    /**
     * spcmTeamManageEditModal
    */
    spcmOpenTeamManageEditModal (type, item) {
      try {
        if (type === "FULLPAGE") {
          this.$router.push("/spcm_manage_team/" + this.spcmManageTeamObj.spcm_id)
        }
        else {
          this.spcmManageTeamObj = item
          this.showModelSpcmManageTeamEdit = true
        }
      }
      catch (err) {
        console.error("Exception occurred at spcmOpenTeamManageEditModal() and Exception:", err.message)
      }
    },
    /**
     * closeManageTeamtModal
     */
    closeManageTeamtModal (updatedData) {
      try {
        if (updatedData) {
          this.StudentCounselorTeamList()
        }
        this.showModelSpcmManageTeamEdit = false
      }
      catch (err) {
        console.error("Exception occurred at closeSpcmEditModal() and Exception:", err.message)
      }
    },
    /**
     * spcmCounsellorEdit
     */
    spcmCounsellorEdit (type, item) {
      try {
        if (type === "FULLPAGE") {
          this.$router.push("/spcm_counselor_edit/" + this.spcmCounsellorEditObj.spcm_id)
        }
        else {
          this.spcmCounsellorEditObj = item
          this.showModelSpcmCounsellorEdit = true
        }
      }
      catch (err) {
        console.error("Exception occurred at spcmCounsellorEdit() and Exception:", err.message)
      }
    },
    /**
     * spcmCounsellorAdd
     */
    spcmCounsellorAdd (type) {
      try {
        if (type === "FULLPAGE") {
          this.$router.push("/spcm_counselor_add")
        }
        else {
          this.showModelSpcmCounsellorAdd = true
        }
      }
      catch (err) {
        console.error("Exception occurred at spcmCounsellorEdit() and Exception:", err.message)
      }
    },
    /**
     * closeSpcmCounsellorEditModal
     */
    closeSpcmCounsellorEditModal (updatedData) {
      try {
        if (updatedData) {
          this.StudentCounselorTeamList()
        }
        this.showModelSpcmCounsellorEdit = false
      }
      catch (err) {
        console.error("Exception occurred at closeSpcmCounsellorEditModal() and Exception:", err.message)
      }
    },
    /**
     * closeSpcmCounsellorAddmodal
     */
    closeSpcmCounsellorAddModal () {
      try {
        this.showModelSpcmCounsellorAdd = false
      }
      catch (err) {
        console.error("Exception occurred at closeSpcmCounsellorAddModal() and Exception:", err.message)
      }
    },
    /**
     * showSpcmCounsellorDeleteDialog
     */
    showSpcmCounsellorDeleteDialog (item) {
      try {
        this.delObj = item
        this.showModelSpcmCounsellorDelete = true
      }
      catch (err) {
        console.error("Exception occurred at showSpcmCounsellorDeleteDialog() and Exception:", err.message)
      }
    },
    /**
     * spcmCounsellorDelete
    */
    async spcmCounsellorDelete () {
      try {
        let spcmCounsellorDelResp = await SpcmCounsellors.spcmCounsellorDelete(this, this.delObj.spcm_counselor_id)
        await ApiResponse.responseMessageDisplay(this, spcmCounsellorDelResp)

        if (spcmCounsellorDelResp && !spcmCounsellorDelResp) {
          this.showModelSpcmCounsellorDelete = false
          return false
        }

        let index = this.stuConselorTeamObjList.indexOf(this.delObj)
        this.stuConselorTeamObjList.splice(index, 1)
        this.totalRows = this.totalRows - 1
        this.showModelSpcmCounsellorDelete = false
      }
      catch (err) {
        console.error("Exception occurred at spcmCounsellorDelete() and Exception:", err.message)
      }
    },
    /**
     * spcmStuCounselorRoleChange
     */
    spcmStuCounselorRoleChange (counselorObj) {
      this.vmspcmStudentTeamCounselorRoleChangeFormData.role = counselorObj.role
      this.vmspcmStudentTeamCounselorRoleChangeFormData.counselor_id = counselorObj.user_id
      this.showModalSpcmStuCounselorRoleEdit = true
    },
    /**
     * closespcmStuTeamCounselorRoleEditModal
     */
    closespcmStuTeamCounselorRoleEditModal () {
      this.showModalSpcmStuCounselorRoleEdit = false
      this.StudentCounselorTeamList()
    },
    /**
     * spcmStudentTeamCounselorRoleEdit
    */
    async spcmStudentTeamCounselorRoleEdit () {
      try {
        // Rest of the params are already set in spcmStuCounselorRoleChange()
        this.vmspcmStudentTeamCounselorRoleChangeFormData.spcm_id = this.propStudentCounsellorObj.spcm_id

        let spcmStudentTeamAddResp = await SpcmCounsellors.spcmStudentTeamCounselorRoleEdit(this, this.vmspcmStudentTeamCounselorRoleChangeFormData)
        await ApiResponse.responseMessageDisplay(this, spcmStudentTeamAddResp)

        if (spcmStudentTeamAddResp && !spcmStudentTeamAddResp.resp_status) {
          return false
        }
        this.closespcmStuTeamCounselorRoleEditModal()
      }
      catch (err) {
        console.error("Exception occurred at spcmStudentTeamCounselorRoleEdit() and Exception:", err.message)
      }
      finally {
        this.cvLoadingStatus = false
      }
    }
  }
}
</script>
<style lang="scss" scoped>
 textarea{
    height: 195px !important;
    overflow-y: scroll;
    line-height: 18px !important;
  }
  .first-letter{
    font-size: 40px;
    font-weight: 600;
    margin: auto;
    text-align: center;
    background-color: #f1f1f1;
  }
  .iq-card-stretch .iq-card-body{
    padding: 0px;
  }
  #stu_coun_team span{
    display: inline-block;
    color: #e5252c;
    font-weight: 500;
  }
  .counsolers_lists{
    display: flex;
    .counsolers_list_image{
      width: 130px;
      height: 130px;
      display: flex;
    }
  }
  @media (min-width:767px) {
    .counsolers_lists{
      .counsolers_list_data{
        width: calc(100% - 130px);
        padding-left: 20px;
      }
    }
  }
  @media (max-width:766px) {
    .counsolers_lists{
      .counsolers_list_data{
        margin-top: 20px;
        width: 100%;
      }
    }
  }
</style>
